import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import Layout from '../components/Layout'

export default function page404() {
  return (
    <Layout pageTitle="404">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <main>
        <ErrorStyled>
          <h2>Oops! Page Not Found...</h2>
          <p>
            Looks like you've followed a broken link or entered a URL that
            doesn't exist on this site. <br /> Just select one of the menu
            points in the navigation above &#10548;
          </p>
        </ErrorStyled>
      </main>
    </Layout>
  )
}

const ErrorStyled = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 20vmin 0;

  > p {
    text-align: center;
  }
`
